<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form action="#" @submit.prevent="showModal({type:'submit',data:payload.persetujuan})">
          <div class="form-body">
            <h3 class="card-title">{{ $t('document_out') }}</h3>
            <hr>
            <div class="row p-t-20">
              <div class="col-md-4">
                <label>
                  {{ $t('agenda_number') }}
                  <small style="color:red">*</small>
                </label>
                <div>
                  <input disabled v-model="payload.agenda" type="text" class="form-control">
                </div>
              </div>
              <div class="col-md-4">
                <label :class="['control-label']">
                  {{ $t('document_urgency') }}
                  <small style="color:red">*</small>
                </label>
                <div :class="[{ 'invalid': invalidSifatSurat }]">
                  <Multiselect
                    :disabled="(editMode && can_send)"
                    v-model="payload.sifat"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_urgency')"
                    open-direction="bottom"
                    :options="dropdownSifatSurat.urgencyList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownSifatSurat.value"
                    :loading="dropdownSifatSurat.isLoadingSifatSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchSifatSurat"
                    @input="onChangeSifatSurat"
                    @search-change="sifatSurat"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="invalidSifatSurat"
                  >{{ $t('at_least_one')}}</label>
                </div>
              </div>
              <div class="col-md-4">
                <div :class="['form-group', {'has-danger': errors.has('tanggal')}]">
                  <label>
                    {{ $t('date') }}
                    <small style="color:red">*</small>
                  </label>
                  <div>
                    <Datepicker
                      :disabled="(editMode && can_send)"
                      wrapper-class="fullscreen-when-on-mobile"
                      input-class="form-control normal-background"
                      v-model="payload.tanggal"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label>
                  {{ $t('page') }}
                  <small style="color:red;">*</small>
                </label>
                <div>
                  <Input v-model="payload.jumlah_halaman" :disabled="(editMode && can_send)" name="jumlah_halaman" required type="number"/>
                </div>
              </div>

              <div class="col-md-4">
                <label>
                  {{ $t('attachment') }}
                  <small style="color:red">*</small>
                </label>
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <Input
                      v-model="payload.jumlah"
                      :placeholder="$t('total')"
                      name="jumlah"
                      :min="0"
                      required
                      type="number"
                      :disabled="(editMode && can_send)"
                    />
                  </div>
                  <div :class="['col-md-6 m-l-5',{ 'invalid': isInvalidLampiran }]">
                    <Multiselect
                      v-model="payload.lampiran"
                      label="text"
                      track-by="value"
                      :placeholder="$t('type_to_search')"
                      open-direction="bottom"
                      :disabled="payload.jumlah === 0 || (editMode && can_send)"
                      :options="dropdownLampiran.lampiranList"
                      :multiple="false"
                      :value="dropdownLampiran.value"
                      :searchable="true"
                      :loading="dropdownLampiran.isLoadingJenisLampiran"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      :selectLabel="''"
                      @input="onChangeLampiran"
                      @close="onTouchLampiran"
                      @search-change="jenisLampiran"
                    >
                      <span slot="noResult">{{ $t('data_not_found') }}</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidLampiran"
                    >{{ $t('at_least_one')}}</label>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <label :class="['control-label']">
                  {{ $t('document_type') }}
                  <small style="color:red">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidJenisSurat }]">
                  <Multiselect
                  :disabled="(editMode && can_send)"
                    v-model="payload.jenis"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_type')"
                    open-direction="bottom"
                    :options="dropdownJenisSurat.categoryList"
                    :multiple="false"
                    :value="dropdownJenisSurat.value"
                    :searchable="true"
                    :loading="dropdownJenisSurat.isLoadingJenisSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeJenisSurat"
                    @close="onTouchJenisSurat"
                    @search-change="jenisSurat"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidJenisSurat"
                  >{{ $t('at_least_one')}}</label>
                </div>
              </div>
              
            </div>

            <div class="row">

              <div class="col-md-4">
                <label :class="['control-label']">
                  {{ $t('document_name') }}
                  <small style="color:red">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidNamaDokumen }]">
                  <Multiselect
                    v-model="payload.nama"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_name')"
                    open-direction="bottom"
                    :options="filteredDocumentName"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownNamaDokumen.value"
                    :loading="dropdownNamaDokumen.isLoadingNamaDokumen"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchNamaDokumen"
                    @input="onChangeNamaDokumen"
                    @search-change="namaDokumen"
                    :disabled="(editMode && can_send)"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidNamaDokumen"
                  >{{ $t('at_least_one')}}</label>
                </div>
              </div>

              <div class="col-md-4">
                <label :class="['control-label']">
                  {{ $t('classification') }}
                  <small style="color:red">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidKlasifikasi }]">
                  <Multiselect
                    v-model="payload.klasifikasi"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_classification')"
                    open-direction="bottom"
                    :disabled="payload.nama.length === 0|| (editMode && can_send)"
                    :options="dropdownKlasifikasi.klasifikasiList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownKlasifikasi.value"
                    :loading="dropdownKlasifikasi.isLoadingKlasifikasi"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchKlasifikasi"
                    @input="onChangeKlasifikasi"
                    @search-change="klasifikasi"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKlasifikasi"
                  >{{ $t('at_least_one')}}</label>
                </div>
              </div>

              <div class="col-md-4">
                <label>
                  {{ $t('document_speed') }}
                  <small style="color:red;">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidKecepatanSurat }]">
                  <Multiselect
                    v-model="payload.kecrat"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_speed')"
                    open-direction="bottom"
                    :disabled="(editMode && can_send)"
                    :options="dropdownKecepatanSurat.kecratList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownKecepatanSurat.value"
                    :loading="dropdownKecepatanSurat.isLoadingKecepatanSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchKecepatanSurat"
                    @input="onChangeKecepatanSurat"
                    @search-change="kecepatanSurat"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKecepatanSurat"
                  >{{ $t('at_least_one')}}</label>
                </div>
              </div>

              <div class="col-md-4">
                <label class="control-label"></label>
                <div class="form-group">
                  <div class="checkbox">
                    <input :disabled="(editMode && can_send)" v-model="payload.tandatanganAtasan" id="checkboxAtasan" type="checkbox" @change="nomorDokumen()">
                    <label for="checkboxAtasan">Perlu Tanda Tangan Atasan</label>
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-4" v-if="payload.nomor_dokumen != ''">
                <label>
                  Nomor Dokumen
                  <small style="color:red">*</small>
                </label>
                <div>
                  <Input 
                    v-model="payload.nomor_dokumen"
                    name="nomor_dokumen"
                    disabled
                    required
                    type="text"
                  />
                </div>
              </div> -->

            </div>

            <hr class="m-t-40">
            <div class="row">
              
              <div class="col-md-12">
                <label>
                  {{ $t('subject') }}
                  <small style="color:red">*</small>
                </label>
                <div>
                  <Input v-model="payload.perihal" name="perihal" required type="text"/>
                </div>
              </div>
            </div>

            <hr class="m-t-40">
            <div class="row">
              <div class="col-md-12">
                <label>
                  {{ $t('receiver_unit') }}
                  <small v-if="editMode && can_send" style="color:red;">*</small>
                </label>
              </div>
              <div class="col-md-4">
                <label>
                  {{ $t('receiver_unit_type') }}
                  <small style="color:red;" v-if="editMode && can_send">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidTipeUnitKerja }]">
                  <Multiselect
                    v-model="payload.tuk"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_receiver_unit_type')"
                    open-direction="bottom"
                    :options="dropdownTipeUnitKerja.tukList"
                    :multiple="false"
                    :value="dropdownTipeUnitKerja.value"
                    :searchable="true"
                    :loading="dropdownTipeUnitKerja.isLoadingTipeUnitKerja"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeTipeUnitKerja"
                    @close="onTouchTipeUnitKerja"
                    @search-change="tipeUnitKerja"
                    @change="unitKerja"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                  <label
                    v-if="editMode && can_send"
                    class="typo__label form__label"
                    v-show="isInvalidTipeUnitKerja"
                  >{{ $t('at_least_one')}}</label>
                </div>
              </div>

              <div class="col-md-8">
                <label>
                  {{ $t('receiver_unit') }}
                  <small style="color:red;" v-if="editMode && can_send">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidUnitKerja }]">
                  <Multiselect
                    v-model="payload.kepada"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_receiver_unit')"
                    open-direction="bottom"
                    :options="filteredUkerList"
                    :multiple="true"
                    :value="dropdownUnitKerja.value"
                    :searchable="true"
                    :disabled="payload.tuk.length === 0"
                    :loading="dropdownUnitKerja.isLoadingUnitKerja"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeUnitKerja"
                    @close="onTouchUnitKerja"
                    @search-change="unitKerja"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                  <label
                    v-if="editMode && can_send"
                    class="typo__label form__label"
                    v-show="isInvalidUnitKerja"
                  >{{ $t('at_least_one')}}</label>
                </div>
              </div>
            </div>
            <hr class="m-t-40">
            <div class="row">
              <div class="col-md-12">
                <label>
                  {{ $t('cc') }}
                </label>
              </div>
              <div class="col-md-4">
                <label>
                  {{ $t('cc_type') }}
                </label>
                <div >
                  <Multiselect
                    v-model="payload.tt"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_cc_type')"
                    open-direction="bottom"
                    :options="dropdownTipeTembusan.tmbsList"
                    :multiple="false"
                    :value="dropdownTipeTembusan.value"
                    :searchable="true"
                    :loading="dropdownTipeTembusan.isLoadingTipeTembusan"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeTipeTembusan"
                    @close="onTouchTipeTembusan"
                    @search-change="tipeTembusan"
                    @change="tembusan"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                </div>
              </div>

              <div class="col-md-8">
                <label>
                  {{ $t('cc') }}
                </label>
                <div>
                  <Multiselect
                    v-model="payload.tembusan"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_cc')"
                    open-direction="bottom"
                    :options="filteredTmbsList"
                    :multiple="true"
                    :value="dropdownTembusan.value"
                    :searchable="true"
                    :disabled="payload.tt.length === 0"
                    :loading="dropdownTembusan.isLoadingTembusan"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeTembusan"
                    @close="onTouchTembusan"
                    @search-change="tembusan"
                  >
                    <span slot="noResult">{{ $t('data_not_found') }}</span>
                  </Multiselect>
                </div>
              </div>
            </div>

            <hr class="m-t-40">


            <div class="row">
              <div class="col-md-12 m-t-10 m-b-10">
                <label :class="['control-label']">Ringkasan Surat</label>
                <wysiwyg v-model="myHTML"/>
              </div>
            </div>
            <div class="row" v-if="editMode">
              <div class="col-md-12">
                <div class="form-group">
                  <label :class="['control-label']">{{ $t('file') }} {{ $t('attachment') }}</label>
                  <h6 v-if="!files.length">{{ $t('no_file') }}</h6>
                  <table v-else class="table table-stripped">
                    <tr>
                      <th>{{ $t('file') }}</th>
                      <th>{{ $t('action') }}</th>
                    </tr>
                    <tr v-for="file in files" v-bind:key="file.filename">
                      <td>
                        {{ file.filepath !==undefined ? file.filename:file.name }}
                      </td>
                      <td>
                        <span v-if="file.filepath !==undefined">
                          <a
                            class="btn btn-success text-white"
                            @click="download(file.uuid,file.filename)"
                            ><i class="fa fa-download text-white"></i>
                          </a>
                          <a
                            @click="prev(file.uuid)"
                            class="btn btn-info text-white"
                            ><i class="fa fa-eye text-white"></i>
                          </a>
                        </span>
                        <i v-else><b>{{ $t('not_saved') }}</b></i>
                      </td>
                    </tr>
                  </table>
                  <Gallery :uploader="uploader"/>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div class="checkbox">
                    <input v-model="payload.tandatanganAtasan" id="checkboxAtasan" type="checkbox">
                    <label for="checkboxAtasan">Perlu Tanda Tangan Atasan</label>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-info" v-if="!(editMode && can_send)" @click="setApproval(2)" title="Simpan">
              <i class="fa fa-check"></i> {{ $t('save') }}
            </button> &nbsp;
            <button v-if="editMode && can_send" type="submit" class="btn btn-success" @click="setApproval(1)" title="Kirim">
              <i class="mdi mdi-send"></i> {{ $t('send') }}
            </button> &nbsp;
            <button type="button" @click="showModal({type:'cancel'})" class="btn btn-inverse" title="Cancel">{{ $t('cancel') }}</button>
          </div>
        </form>
        <ModalDefault v-show="isVisible" :title="modalTitle" :content="modalContent" :action="ActionChangeable" :confirmation="isConfirmation" :information="isInformation" @close="onClose"/>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect,
    Datepicker
  },
  computed: {
    filteredDocumentName(){
      let filter = this.payload.jenis;
      let data = this.dropdownNamaDokumen.nameList.filter(elem=>{
        return elem.parent == filter.value;
      });
      return data;
    },
    filteredUkerList(){
      let filt = this.payload.tembusan;
      let list = this.dropdownUnitKerja.ukerList.filter(uker=>{
        let exist = false;
        filt.forEach(element => {
          if(element.value==uker.value){
            exist = true;
          }
        });
        return exist == false;
      });
      let currentId = JSON.parse(localStorage.getItem('user'));
      list = list.filter(element=>{
        return element.value != currentId.unit_id;
      })
      return list;
    },
    filteredTmbsList(){
      let filt = this.payload.kepada;
      let list = this.dropdownTembusan.tmbsList.filter(tmbs=>{
        let exist = false;
        filt.forEach(element => {
          if(element.value==tmbs.value){
            exist = true;
          }
        });
        return exist == false; 
      });
      let currentId = JSON.parse(localStorage.getItem('user'));
      list = list.filter(element=>{
        return element.value != currentId.unit_id;
      })
      return list;
    },
    isInvalidKecepatanSurat() {
      return (
        this.dropdownKecepatanSurat.isTouched &&
        this.dropdownKecepatanSurat.value.length === 0
      );
    },
    isInvalidTipeUnitKerja() {
      return (
        this.dropdownTipeUnitKerja.isTouched &&
        this.dropdownTipeUnitKerja.value.length === 0
      );
    },
    isInvalidTipeTembusan() {
      return (
        this.dropdownTipeTembusan.isTouched &&
        this.dropdownTipeTembusan.value.length === 0
      );
    },
    isInvalidUnitKerja() {
      return (
        this.dropdownUnitKerja.isTouched &&
        this.dropdownUnitKerja.value.length === 0
      );
    },
    isInvalidTembusan() {
      return (
        this.dropdownTembusan.isTouched &&
        this.dropdownTembusan.value.length === 0
      );
    },
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.documentRetracted;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidNamaDokumen() {
      return (
        this.dropdownNamaDokumen.isTouched &&
        this.dropdownNamaDokumen.value.length === 0
      );
    },
    isInvalidKlasifikasi() {
      return (
        this.dropdownKlasifikasi.isTouched &&
        this.dropdownKlasifikasi.value.length === 0
      );
    },
    isInvalidJenisSurat() {
      return (
        this.dropdownJenisSurat.isTouched &&
        this.dropdownJenisSurat.value.length === 0
      );
    },
    invalidSifatSurat() {
      return (
        this.dropdownSifatSurat.isTouched &&
        this.dropdownSifatSurat.value.length === 0
      );
    },
    isInvalidLampiran() {
      return (
        this.dropdownLampiran.isTouched &&
        this.dropdownLampiran.value.length === 0
      );
    }
  },
  data() {
    const uploader = this.fineUploader(this, "document_out");
    return {
      uploader,
      files: [],
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      can_send: false,
      processing_unit:'',
      signing:'',
      dropdownKecepatanSurat: {
        isTouched: false,
        kecratList: [],
        value: [],
        isLoadingKecepatanSurat: false
      },
      payload: {
        kecrat:'',
        tt:[],
        tuk:[],
        tas:[],
        asrat: [],
        tanggal: "",
        agenda: "",
        dari: "",
        sifat: [],
        jenis: [],
        kepada: [],
        nama: [],
        lampiran: {value: 2, text:'Lembar'},
        klasifikasi: [],
        tembusan: [],
        unitKerjaDituju: [],
        tembusanUnitTarget: [],
        perihal: "",
        referensi: "",
        valueJenisDokumen: [],
        valueSifatSurat: [],
        keterangan: "",
        valueIndeks: [],
        nomor_dokumen: "",
        ministerApproval: "",
        tandatanganAtasan: "",
        file: "",
        jumlah: 0,
        jumlah_halaman:1,
        persetujuan: 0
      },
      dropdownSifatSurat: {
        isTouched: false,
        urgencyList: [],
        value: [],
        isLoadingSifatSurat: false
      },
      dropdownJenisSurat: {
        isTouched: false,
        categoryList: [],
        value: [],
        isLoadingJenisSurat: false
      },
      dropdownLampiran: {
        isTouched: false,
        lampiranList: [],
        value: {value: 2, text:'Lembar'},
        isLoadingJenisLampiran: false
      },
      dropdownNamaDokumen: {
        isTouched: false,
        nameList: [],
        value: [],
        isLoadingNamaDokumen: false
      },
      dropdownKlasifikasi: {
        isTouched: false,
        klasifikasiList: [],
        value: [],
        isLoadingKlasifikasi: false
      },
      dropdownTujuan: {
        isTouched: false,
        userList: [],
        value: [],
        isLoadingUserFind: false
      },
      dropdownTembusan: {
        isTouched: false,
        value: [],
        tembusanList: [],
        isLoadingTembusan: false
      },
      receiver: {
        isTouched: false,
        value: [],
        list: [],
        isLoadingUserList: false
      },
      dropdownTipeUnitKerja: {
        isTouched: false,
        tukList: [],
        value: [],
        isLoadingTipeUnitKerja: false
      },
      dropdownTipeTembusan: {
        isTouched: false,
        tmbsList: [],
        value: [],
        isLoadingTipeTembusan: false
      },
      dropdownUnitKerja: {
        isTouched: false,
        ukerList: [],
        value: [],
        isLoadingUnitKerja: false
      },
      dropdownTembusan: {
        isTouched: false,
        tmbsList: [],
        value: [],
        isLoadingTembusan: false
      },
      myHTML: "",
      myFile: [],
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: '',
      modalContent:'',
      isConfirmation:false,
      isInformation:false,
      ActionChangeable:null
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
  },

  methods: {
    onClose(){
      this.isVisible = false;
    },
    showModal(option){
      let vm = this;
      this.isVisible = true;
      if(option.type == 'submit'){
        if(option.data == 2){
          this.modalTitle = this.$t('save_data_q');
          this.modalContent = "<p>"+ this.$t('save_data_confirm_q') +"</p>";
          this.isConfirmation = true;
          this.isInformation = false;
          this.ActionChangeable = this.submit;
        }else if(option.data == 1){
          this.modalTitle = this.$t('send_data_q');
          this.modalContent = "<p>"+ this.$t('send_data_confirm_q') +"</p>";
          this.isConfirmation = true;
          this.isInformation = false;
          this.ActionChangeable = this.submit;
        }
      }else if(option.type == 'cancel'){
        this.modalTitle = this.$t('cancel_q');
        this.modalContent = "<p>"+this.$t('cancel_confirm_q')+"</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }else if(option.type=='information'){
        this.modalTitle = this.$t('success');
        this.modalContent = this.$t('document_out_created',{document_number:option.document_number});
        this.isConfirmation = true;
        this.ActionChangeable = function(){
          vm.$router.push({
            name:'documentRetracted'
          });
        };
        this.isInformation = true;
      }
    },
    kecepatanSurat(query) {
      this.dropdownKecepatanSurat.isLoadingKecepatanSurat = true;
      axios
        .get(`document_speeds/list?s=${query}`)
        .then(res => {
          this.dropdownKecepatanSurat.kecratList = res.data.items;
          this.dropdownKecepatanSurat.isLoadingKecepatanSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    onChangeKecepatanSurat(value) {
      this.dropdownKecepatanSurat.value = value;
    },
    onTouchKecepatanSurat() {
      this.dropdownKecepatanSurat.isTouched = true;
    },
    setApproval(value) {
      this.payload.persetujuan = value;
    },
    getAllReference() {
      var query = "";
      this.kecepatanSurat(query);
      this.asyncFind(query);
      this.sifatSurat(query);
      this.tipeUnitKerja(query);
      this.tipeTembusan(query);
      this.jenisSurat(query);
      this.namaDokumen(query);
      this.klasifikasi(query);
      this.jenisLampiran(query);
        axios.get(`/document_out/new`).then(response => {
          if(!this.editMode){
            this.payload.agenda = response.data.data.agenda_number;
            this.payload.tanggal = new Date();
          }
          this.signing = response.data.data.signing;
          this.processing_unit = response.data.data.processing_unit;
        });  
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/document_out/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("documentRetracted/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          axios
          .get(`attachment_types/list`)
          .then(res => {
            for(let at in res.data.items){
              if(res.data.items[at].text == "Lembar"){
                this.dropdownLampiran.value = res.data.items[at];
                this.payload.lampiran = res.data.items[at];
              }
            }
          })
          .catch(err => {
            
          });
          this.$store.dispatch("documentRetracted/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        klasifikasi: data.clasification_type,
        nomor_dokumen: data.document_number,
        tanggal: new Date(data.document_date),
        agenda: data.agenda_number,
        jenis: data.document_category,
        perihal: data.subject,
        jumlah: data.attachment_total,
        lampiran: data.attachment_type,
        sifat: data.document_urgency,
        kecrat: data.document_speed,
        nama: data.document_name,
        kepada: data.receiver,
        tembusan: data.carbon,
        tuk:data.receiver_type,
        tt:data.carbon_type,
        unitKerjaDituju: ["hallo", ""],
        tembusanUnitTarget: [],
        files: data.files,
        jumlah_halaman: data.pages,
        ministerApproval: data.minister_approval,
        tandatanganAtasan: data.superior
      };
      this.can_send = data.can_send;
      this.files = data.files;
      this.dropdownKecepatanSurat.value = data.document_speed;
      this.dropdownSifatSurat.value = data.document_urgency;
      this.dropdownJenisSurat.value = data.document_category;
      this.dropdownLampiran.value = data.attachment_type;
      this.dropdownKlasifikasi.value = data.clasification_type;
      this.dropdownNamaDokumen.value = data.document_name;
      this.dropdownUnitKerja.value = data.receiver;
      this.dropdownTipeUnitKerja.value = data.receiver_type;
      this.dropdownTembusan.value = data.carbon;
      this.dropdownTipeTembusan.value = data.carbon_type;
      this.myHTML = data.description;
      this.unitKerja('');
      this.tembusan('');
    },
    goBack() {
      this.$store.dispatch("documentRetracted/onCancel");
    },
    onTouchTipeUnitKerja() {
      this.dropdownTipeUnitKerja.isTouched = true;
    },
    onTouchTipeTembusan() {
      this.dropdownTipeTembusan.isTouched = true;
    },
    onTouchUnitKerja() {
      this.dropdownUnitKerja.isTouched = true;
    },
    onTouchTembusan() {
      this.dropdownTembusan.isTouched = true;
    },
    onChangeTipeUnitKerja(value) {
      this.dropdownTipeUnitKerja.value = value;
      this.unitKerja();
    },
    onChangeTipeTembusan(value) {
      this.dropdownTipeTembusan.value = value;
      this.tembusan();
    },
    onChangeUnitKerja(value) {
      this.dropdownUnitKerja.value = value;
    },
    onChangeTembusan(value) {
      this.dropdownTembusan.value = value;
    },
    tipeUnitKerja(query) {
      this.dropdownTipeUnitKerja.isLoadingTipeUnitKerja = true;
      axios
        .get(`typeSurat/list?s=${query}`)
        .then(res => {
          this.dropdownTipeUnitKerja.tukList = res.data.items;
          this.dropdownTipeUnitKerja.isLoadingTipeUnitKerja = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    tipeTembusan(query) {
      this.dropdownTipeTembusan.isLoadingTipeTembusan = true;
      axios
        .get(`typeSurat/list?s=${query}`)
        .then(res => {
          this.dropdownTipeTembusan.tmbsList = res.data.items;
          this.dropdownTipeTembusan.isLoadingTipeTembusan = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    unitKerja(query) {
      const mv = this;
      mv.dropdownUnitKerja.isLoadingUnitKerja = true;
      let address = '';
      // if(mv.payload.tuk.value == 3){
      //   address = `kategoriAsalSurat/listByLevel?s=${query ? query : ""}`
      // }else{
        address = `units/listByLevel?s=${query ? query : ""}&type_code=${
            mv.payload.tuk.value
          }`
      // }
      axios
        .get(address)
        .then(res => {
          mv.dropdownUnitKerja.ukerList = res.data.items;
          mv.dropdownUnitKerja.isLoadingUnitKerja = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            mv.$store.dispatch("auth/logout");
          }
        });
    },
    tembusan(query) {
      const mv = this;
      mv.dropdownTembusan.isLoadingTembusan = true;
      let address = '';
      // if(mv.payload.tt.value == 3){
      //   address = `kategoriAsalSurat/listByLevel?s=${query ? query : ""}`
      // }else{
        address = `units/listByLevel?s=${query ? query : ""}&type_code=${
            mv.payload.tt.value
          }`
      // }
      axios
        .get(address)
        .then(res => {
          mv.dropdownTembusan.tmbsList = res.data.items;
          mv.dropdownTembusan.isLoadingTembusan = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            mv.$store.dispatch("auth/logout");
          }
        });
    },
    submit() {
      // this.onTouchTipeUnitKerja();
      // this.onTouchUnitKerja();
      this.onTouchNamaDokumen();
      this.onTouchSifatSurat();
      this.onTouchKecepatanSurat();
      this.onTouchKlasifikasi();
      this.onTouchJenisSurat();
      if (
        this.dropdownSifatSurat.value.length === 0 &&
        this.dropdownJenisSurat.value.length === 0 &&
        this.dropdownLampiran.value.length === 0 &&
        // this.dropdownUnitKerja.value.length === 0 &&
        // this.dropdownTipeUnitKerja.value.length === 0 &&
        this.dropdownNamaDokumen.value.length === 0 &&
        this.dropdownKecepatanSurat.value.length === 0 &&
        this.dropdownKlasifikasi.value.length === 0
      ) {
        this.dropdownSifatSurat.isTouched = true;
        this.dropdownJenisSurat.isTouched = true;
        this.dropdownLampiran.isTouched = true;
        // this.dropdownTipeUnitKerja.isTouched = true;
        // this.dropdownUnitKerja.isTouched = true;
        this.dropdownNamaDokumen.isTouched = true;
        this.dropdownKecepatanSurat.isTouched = true;
        this.dropdownKlasifikasi.isTouched = true;
        window.scrollTo(0, 0);
      }
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }
      const payload = {
        clasification_type: this.payload.klasifikasi,
        receiver_type: this.payload.tuk,
        carbon_type: this.payload.tt,
        receiver: this.payload.kepada,
        carbon: this.payload.tembusan,
        agenda_number: this.payload.agenda,
        document_date: this.payload.tanggal.toISOString().slice(0, 10),
        document_urgency: this.payload.sifat,
        document_category: this.payload.jenis,
        document_speed: this.payload.kecrat,
        subject: this.payload.perihal,
        document_name: this.payload.nama,
        document_number: this.payload.nomor_dokumen,
        description: this.myHTML,
        files: this.files,
        pages: this.payload.jumlah_halaman,
        attachment_total: this.payload.jumlah,
        attachment_type: this.payload.lampiran,
        minister_approval: this.payload.ministerApproval ? 1 : 0,
        approval: this.payload.persetujuan,
        superior: this.payload.tandatanganAtasan ? 1 : 0
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (
          success &&
          !this.isInvalidJenisSurat &&
          !this.isInvalidNamaDokumen &&
          !this.isInvalidKlasifikasi &&
          !this.isInvalidLampiran &&
          // !this.isInvalidUnitKerja &&
          // !this.isInvalidTipeUnitKerja &&
          !this.isInvalidKecepatanSurat &&
          !this.invalidSifatSurat
        ) {
          const state = {
            loaded: false
          };
          this.$store.commit("documentRetracted/STATE", state);
          if(this.editMode && this.can_send){
            this.onTouchTipeUnitKerja();
            this.onTouchUnitKerja();
            if(!this.isInvalidUnitKerja && !this.isInvalidTipeUnitKerja){
              this.$store.dispatch("documentRetracted/submitEdit", {
                data,
                id: this.$route.params.id
              }).then(result=>{
                const state = {
                  loaded: true
                };
                this.$store.commit("documentRetracted/STATE", state);
                this.showModal({type:'information',document_number:result.data.data.document_number});
                this.isVisible = true;
              }).catch(err=>{
                const state = {
                  loaded: true
                };
                this.$store.commit("documentRetracted/STATE", state);
                this.isVisible = false;
              });
            }else{
              const state = {
                loaded: true
              };
              this.$store.commit("documentRetracted/STATE", state);
              const message = {
                title: this.$t('error'),
                text: this.$t('fill_form_first'),
                type: "error"
              };
              this.$store.commit("addNotification", message);
              window.scrollTo(0, 0);
              this.isVisible = false;
            }
          }
          else if (this.editMode) {
            this.$store.dispatch("documentRetracted/submitEdit", {
              data,
              id: this.$route.params.id
            }).then(result=>{
              const state = {
                loaded: true
              };
              this.$store.commit("documentRetracted/STATE", state);
              this.showModal({type:'information',document_number:result.data.data.document_number});
              this.isVisible = true;
            }).catch(err=>{
              const state = {
                loaded: true
              };
              this.$store.commit("documentRetracted/STATE", state);
              this.isVisible = false;
            });
          } else {
            this.$store.dispatch("documentRetracted/submitAdd", data).then(result=>{
              const state = {
                loaded: true
              };
              this.$store.commit("documentRetracted/STATE", state);
              this.showModal({type:'information',document_number:result.data.data.document_number});
              this.isVisible = true;
            }).catch(err=>{
              const state = {
                loaded: true
              };
              this.$store.commit("documentRetracted/STATE", state);
              this.isVisible = false;
            });
          }
        }else{
            const state = {
              loaded: true
            };
            this.$store.commit("documentRetracted/STATE", state);
            const message = {
              title: this.$t('error'),
              text: this.$t('fill_form_first'),
              type: "error"
            };
            this.$store.commit("addNotification", message);
            window.scrollTo(0, 0);
            this.isVisible = false;
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },
    asyncFind(query) {
      this.dropdownTujuan.isLoadingUserFind = true;
      axios
        .get(`units/list?s=${query}`)
        .then(res => {
          this.dropdownTujuan.userList = res.data.items;
          this.dropdownTujuan.isLoadingUserFind = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    sifatSurat(query) {
      this.dropdownSifatSurat.isLoadingSifatSurat = true;
      axios
        .get(`document_urgencies/list?s=${query}`)
        .then(res => {
          this.dropdownSifatSurat.urgencyList = res.data.items;
          this.dropdownSifatSurat.isLoadingSifatSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    jenisSurat(query) {
      this.dropdownJenisSurat.isLoadingJenisSurat = true;
      axios
        .get(`document_categories/list?s=${query}`)
        .then(res => {
          this.dropdownJenisSurat.categoryList = res.data.items;
          this.dropdownJenisSurat.isLoadingJenisSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    namaDokumen(query) {
      this.dropdownNamaDokumen.isLoadingNamaDokumen = true;
      axios
        .get(`clasification/list?s=${query}`)
        .then(res => {
          this.dropdownNamaDokumen.nameList = res.data.items;
          this.dropdownNamaDokumen.isLoadingNamaDokumen = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    klasifikasi(query) {
      this.dropdownKlasifikasi.isLoadingKlasifikasi = true;
      axios
        .get(`clasificationdoc/list?s=${query}`)
        .then(res => {
          this.dropdownKlasifikasi.klasifikasiList = res.data.items;
          this.dropdownKlasifikasi.isLoadingKlasifikasi = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    jenisLampiran(query) {
      this.dropdownLampiran.isLoadingJenisLampiran = true;
      axios
        .get(`attachment_types/list?s=${query}`)
        .then(res => {
          this.dropdownLampiran.lampiranList = res.data.items;
          this.dropdownLampiran.isLoadingJenisLampiran = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    onTouchNamaDokumen() {
      this.nomorDokumen();
      this.dropdownNamaDokumen.isTouched = true;
    },
    onTouchKlasifikasi() {
      this.nomorDokumen();
      this.dropdownKlasifikasi.isTouched = true;
    },
    onTouchLampiran() {
      this.dropdownLampiran.isTouched = true;
    },
    onTouchJenisSurat() {
      this.dropdownJenisSurat.isTouched = true;
    },
    onTouchSifatSurat() {
      this.dropdownSifatSurat.isTouched = true;
    },
    onChangeSifatSurat(value) {
      this.dropdownSifatSurat.value = value;
    },
    onChangeLampiran(value) {
      this.dropdownLampiran.value = value;
    },
    onChangeJenisSurat(value) {
      this.dropdownJenisSurat.value = value;
    },
    onChangeNamaDokumen(value) {
      this.dropdownNamaDokumen.value = value;
    },
    onChangeKlasifikasi(value) {
      this.dropdownKlasifikasi.value = value;
    },
    nomorDokumen(value) {
      if(this.payload.nama.code !== undefined && this.payload.klasifikasi.code !=undefined){
        if(this.payload.tandatanganAtasan){
          this.payload.nomor_dokumen = this.payload.nama.code.replace('kode klas',this.payload.klasifikasi.code);
          this.payload.nomor_dokumen = this.payload.nomor_dokumen.replace('/Penandatangan', ('/'+this.signing));
          this.payload.nomor_dokumen = this.payload.nomor_dokumen.replace('Unit Pengolah', this.processing_unit);
        }else{
          this.payload.nomor_dokumen = this.payload.nama.code.replace('kode klas',this.payload.klasifikasi.code);
          this.payload.nomor_dokumen = this.payload.nomor_dokumen.replace('/Penandatangan', '');        
          this.payload.nomor_dokumen = this.payload.nomor_dokumen.replace('Unit Pengolah', this.processing_unit);
        }
      }
    },
    async onChangeData(value) {
      try {
        const response = await axios.get(`/units/listById`,{value});
        response = response.data;
        this.receiver.list = response.data;
      } catch (e) {
      }
    },
    download(filepath,filename){
      axios.post('document_out/assets/single',{filepath:filepath}).then(res=>{
        const linkSource = res.data;
        const downloadLink = document.createElement("a");
        const fileName = filename;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }).catch(err=>{
        console.log(err);
      });
    },
    prev(string){
      let routeData = this.$router.resolve({name:'preview.index',
        params:{
          document_type:'document_out',
          id:this.$route.params.id,
          file:string
        }
        });
        window.open(routeData.href, "_blank");
    },
    onSelectUnitKerja(value){
      this.payload.kepada.push({value:value.value,text:value.text});
    },
    onDeselectUnitKerja(value){
      for(let ke in this.payload.kepada){
        if(this.payload.kepada[ke].value==value.value){
          this.payload.kepada.splice(ke,1);
        }
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>
<style lang="scss">
.vdp-datepicker {
  &.fullscreen-when-on-mobile {
    @media (max-width: 767px) {
      position: static;
      .vdp-datepicker__calendar {
        position: fixed;
        top: 50%;
        left: 5%;
        margin-top: -25%;
        width: 90%;
      }
    }
  }
}
</style>
